<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <a-back-top />
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="事件标题">
                <a-input placeholder="请输入事件标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="上报人">
                <a-input
                  placeholder="请输入上报人姓名"
                  v-model="queryParam.realName"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="处理状态">
                <a-select
                  v-model="queryParam.status"
                  placeholder="请选择处理状态"
                  allow-clear
                >
                  <a-select-option :key="-1" :value="-1">请选择处理状态</a-select-option>
                  <a-select-option v-for="item in statusList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="区域">
                  <a-cascader
                    :options="regionList"
                    placeholder="省-市-区(县)"
                    :field-names="{label: 'label', value: 'value', children: 'children' }"
                    :value="defaultRegion"
                    @change="changeRegion"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择小区">
                  <a-select
                    v-model="queryParam.communityId"
                    placeholder="请选择小区"
                    allow-clear
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="事件类型">
                  <a-select
                    v-model="queryParam.eventType"
                    placeholder="请选择事件类型"
                    allow-clear
                  >
                    <a-select-option :key="0" :value="0">请选择事件类型</a-select-option>
                    <a-select-option v-for="item in eventTypeList" :key="item.keyId" :value="item.itemId">
                      {{ item.itemName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="事件级别">
                  <a-select
                    v-model="queryParam.eventLevel"
                    placeholder="请选择事件级别"
                    allow-clear
                  >
                    <a-select-option :key="0" :value="0">请选择事件级别</a-select-option>
                    <a-select-option v-for="item in eventLevelList" :key="item.keyId" :value="item.itemId">
                      {{ item.itemName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="事件来源">
                  <a-select
                    v-model="queryParam.eventSource"
                    placeholder="请选择事件来源"
                    allow-clear
                  >
                    <a-select-option :key="0" :value="0">请选择事件来源</a-select-option>
                    <a-select-option v-for="item in eventSourceList" :key="item.keyId" :value="item.itemId">
                      {{ item.itemName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="searchForm">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-card :bordered="false">
        <a-spin :spinning="loading">
          <a-list size="large" :pagination="pagination">
            <a-list-item :key="index" v-for="(item, index) in data">
              <a-list-item-meta :description="item.descStr">
                <a slot="title" @click="showSeeInfo(item.keyId)">
                  {{ item.title }}
                  <a-tag color="gold" v-if="item.status === 0">{{ item.statusName }}</a-tag>
                  <a-tag color="green" v-else-if="item.status === 1">{{ item.statusName }}</a-tag>
                  <a-tag color="pink" v-else-if="item.status === 3">{{ item.statusName }}</a-tag>
                  <a-tag v-else-if="item.status === 4">{{ item.statusName }}</a-tag>
                  <a-tag color="cyan" v-else>{{ item.statusName }}</a-tag>
                </a>
              </a-list-item-meta>
              <div slot="actions">
                <a @click="handleDealEvent(item)">操作</a>
                <a-divider type="vertical" />
                <a @click="showSeeInfo(item.keyId)">查看</a>
              </div>
              <div class="list-content">
                <div class="list-content-item">
                  <span style="width: 100px;text-align: left;">上报人</span>
                  <p style="width: 100px;text-align: left;" v-if="item.reporterHeadPic !== ''"><a-avatar slot="avatar" size="small" :src="item.reporterHeadPic"/> {{ item.reporterName }}</p>
                  <p style="width: 100px;text-align: left;" v-else><a-avatar slot="avatar" size="small">U</a-avatar> {{ item.reporterName }}</p>
                  <p style="width: 100px;text-align: left;">{{ item.reporterTelephone }}</p>
                </div>
                <div class="list-content-item">
                  <span style="width: 140px;text-align: left;">评分</span>
                  <p style="width: 140px;text-align: left;">
                    <a-rate v-model="item.score" :disabled="true" />
                  </p>
                </div>
                <div class="list-content-item">
                  <span style="width: 60px;text-align: left;">事件类型</span>
                  <p style="width: 60px;text-align: left;">{{ item.eventTypeName }}</p>
                </div>
                <div class="list-content-item">
                  <span style="width: 60px;text-align: left;">事件级别</span>
                  <p style="width: 60px;text-align: left;">
                    <a-tag color="red" v-if="item.eventLevel === '002-4'">{{ item.eventLevelName }}</a-tag>
                    <a-tag color="orange" v-else-if="item.eventLevel === '002-3'">{{ item.eventLevelName }}</a-tag>
                    <a-tag color="cyan" v-else-if="item.eventLevel === '002-2'">{{ item.eventLevelName }}</a-tag>
                    <a-tag color="green" v-else>{{ item.eventLevelName }}</a-tag>
                  </p>
                </div>
                <div class="list-content-item">
                  <span style="width: 60px;text-align: left;">来源</span>
                  <p style="width: 60px;text-align: left;">{{ item.eventSourceName }}</p>
                </div>
                <div class="list-content-item">
                  <span style="text-align: left;">小区</span>
                  <p style="text-align: left;">{{ item.areaCodeName }}{{ item.communityName }}</p>
                </div>
                <div class="list-content-item">
                  <span>发生时间</span>
                  <p>{{ item.eventTime }}</p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
      </a-card>
      <see-event ref="seeevent" @refreshData="refreshData" :statusList="statusList"></see-event>
      <deal-event
        ref="dealevent"
        :visible="visible"
        :eventDealData="eventDealData"
        :statusList="statusList"
        @cancel="handleCancel"
        @ok="handleOk"
      >
      </deal-event>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import { eventCondition, eventList } from '@/api/event'
import SeeEvent from '@/views/event/components/SeeEvent'
import DealEvent from '@/views/event/components/DealEvent'

export default {
  name: 'HealthreportList',
  components: {
    SeeEvent,
    DealEvent
  },
  data () {
    return {
      eventDealData: {},
      loading: false,
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        realName: '',
        title: '',
        provinceId: 0,
        communityId: 0,
        cityId: 0,
        areaId: 0,
        pageNo: 1,
        status: -1,
        pageSize: 10,
        eventType: 0,
        eventLevel: 0,
        eventSource: 0,
        eventGroup: 'HealthEventRecord'
      },
      regionList: [],
      defaultRegion: [],
      data: [],
      pagination: {
        onChange: (page, pageSize) => this.onChange(page, pageSize),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        pageSize: 10,
        total: 0,
        defaultCurrent: 1,
        current: 1
      },
      regionCommunity: [],
      dealSuggestLoading: false,
      statusList: [],
      eventLevelList: [],
      eventSourceList: [],
      eventTypeList: []
    }
  },
  filters: {
  },
  created () {
    this.eventConditionApi()
    this.eventListApi()
    this.regionListApi()
  },
  methods: {
    eventListApi () {
      const _this = this
      _this.loading = true
      eventList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.data = res.result.data
          _this.pagination.pageSize = res.result.pageSize
          _this.pagination.total = res.result.totalCount
          _this.pagination.current = res.result.pageNo
          _this.loading = false
          console.log('pagination', _this.pagination)
        }
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    },
    eventConditionApi () {
      const _this = this
      eventCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.statusList = res.result.statusList
          _this.eventLevelList = res.result.eventLevelList
          _this.eventSourceList = res.result.eventSourceList
          _this.eventTypeList = res.result.eventTypeList
          console.log('eventCondition', res)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    searchForm () {
      this.queryParam.pageNo = 1
      this.eventListApi()
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.regionCommunity = []
      this.queryParam = {
        realName: '',
        title: '',
        provinceId: 0,
        communityId: 0,
        status: -1,
        cityId: 0,
        areaId: 0,
        pageNo: 1,
        pageSize: 10,
        eventType: 0,
        eventLevel: 0,
        eventSource: 0,
        eventGroup: 'HealthEventRecord'
      }
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaId = e[2] ? e[2] : 0
      this.regionCommunityApi(this.queryParam.areaId)
      this.queryParam.communityId = 0
    },
    onChange (page, pageSize) {
      this.queryParam.pageNo = page
      this.queryParam.pageSize = pageSize
      this.eventListApi()
    },
    regionCommunityApi (areaCode) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionCommunity = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    showSeeInfo (keyId) {
      this.$refs.seeevent.eventInfoApi(keyId)
    },
    refreshData (e) {
      console.log(e)
      this.eventListApi()
    },
    handleDealEvent (data) {
      this.eventDealData = data
      this.$refs.dealevent.getTokenApi()
      this.visible = true
    },
    handleCancel (e) {
      this.eventDealData = {}
      console.log('handleCancel', e)
      this.visible = false
    },
    handleOk () {
      this.visible = false
      this.eventListApi()
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    showSizeChange (current, pageSize) {
      this.pagination.current = 1
      this.pagination.defaultCurrent = 1
      this.pagination.pageSize = pageSize
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.eventListApi()
    }
  },
  watch: {
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 20px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
